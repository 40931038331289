export function addLinkTagInHead(href: string, rel = '', type = ''): void {
	if (document?.head && href) {
		const linkEl = document.createElement('link');
		linkEl.href = href;
		linkEl.rel = rel;
		if (type) linkEl.type = type;
		document.head.appendChild(linkEl);
	}
}

export function parseScriptAndApply(script: string) {
	// Split the string into individual scripts, capturing attributes
	const scriptArray = script.match(/<script(.*?)>(.*?)<\/script>/gs);

	if (scriptArray) {
		scriptArray.forEach(script => {
			// Extract the script attributes and content
			const [, attributes, scriptContent] = script.match(/<script(.*?)>(.*?)<\/script>/s) || [];

			// Create a new script tag
			const newScript = document.createElement('script');

			// Set script attributes
			newScript.textContent = scriptContent;
			attributes.match(/\S+="[^"]*"/g)?.forEach(attr => {
				const [name, value] = attr.split('=');
				newScript.setAttribute(name, value.slice(1, -1));
			});

			// Append the new script to the body
			document.body.appendChild(newScript);
		});
	} else {
		// If no <script> tags are found, execute the string as a single script
		const newScript = document.createElement('script');
		newScript.textContent = script;
		document.body.appendChild(newScript);
	}
}
